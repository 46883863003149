// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";  // Импортируем getAuth

const firebaseConfig = {
  apiKey: "AIzaSyCgOQrmJtE19FoGp8hrHmFixZznQbBpz7E",
  authDomain: "tojsokhtmon-1bc72.firebaseapp.com",
  projectId: "tojsokhtmon-1bc72",
  storageBucket: "tojsokhtmon-1bc72.appspot.com",
  messagingSenderId: "794227614175",
  appId: "1:794227614175:web:5ee9030be77e9ed88de46a",
  measurementId: "G-MEN6Y5GHJG"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);  // Инициализируем auth

export { app, db, auth };
